<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div class="row">
        <div class="col-1" style="height: 48px;">
          <b-button
            size="sm"
            variant="primary"
            @click="
              () => {
                $router.go(-1);
              }
            "
          >
            <i class="icon-arrow-left"></i> Назад
          </b-button>
        </div>
        <div class="col-8 text-center">
          <i v-if="isPageLoading" class="fa fa-cog fa-spin fa-4x"></i>
          <div v-if="isPageLoading" id="overlay"></div>
        </div>
      </div>
      <b-form-group
        id="itemName"
        label="Название прошивки"
        label-for="itemName"
      >
        <div class="input-group mb-1">
          <b-form-input
            id="title"
            type="text"
            v-model="firmware.Title"
            trim
          ></b-form-input>
        </div>
      </b-form-group>

      <b-form-group
        id="fileInputLabel"
        label="Бинарный файл прошивки"
        label-for="fileInput"
      >
        <b-form-file
          id="fileInput"
          name="fileInput"
          v-model="firmware.Bin"
          accept=".bin"
        ></b-form-file>
      </b-form-group>

      <b-form-group label="Тип прошивки" description="">
        <b-form-radio-group
          id="itemType"
          v-model="firmware.FirmwareType"
          :options="types"
          name="itemType"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Описание" description="">
        <textarea
          class="form-control"
          id="itemDescText"
          name="itemDescText"
          style="min-height: 56px"
          rows="2"
          placeholder="Добавьте описание к прошивке (необязательно)"
          v-model="firmware.Desc"
          trim
        >
        </textarea>
      </b-form-group>

      <b-form-group
        id="tag"
        label="Тег"
        label-for="tag"
      >
        <div class="input-group mb-1">
          <b-form-input
            id="tag"
            type="text"
            v-model="firmware.Tag"
            trim
          ></b-form-input>
        </div>
      </b-form-group>

      <b-form-group
        label="Текущая?"
        description="Такая прошивка станет текущей"
      >
        <b-form-checkbox
          v-model="firmware.IsCurrent"
          name="check-button"
          switch
        >
        </b-form-checkbox>
      </b-form-group>

    </div>
    <hr />
    <div class="row">
      <div class="col-1" style="height: 48px;">
        <b-button
          size="sm"
          variant="success"
          :disabled="firmware.Title.length < 3 || firmware.Bin.length === 0"
          @click="save"
        >
          <i class="icon-circle-check"></i> Добавить
        </b-button>
      </div>
    </div>

    <b-modal
      ref="codeModal"
      size="md"
      ok-only
      no-stacking
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="$router.push({ name: 'Прошивки' })"
      :title="'Прошивка добавлена'"
    >
      <div class="d-block" v-if="confirmData">
        Версия прошивки "{{ firmware.Title }}" (ID = {{ confirmData.Id }}):
        <h1 class="text-center">{{ confirmData.Version }}</h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
import API from "../../../api/stalk.net";
import "blueimp-canvas-to-blob";

export default {
  name: "ItemEdit",
  data() {
    let firmware = {
      //default value for creation
      Id: 0,
      Title: "",
      Tag: "",
      Desc: "",
      FirmwareType: API.consts.FirmwareTypes.Artefact,
      Bin: "",
      IsCurrent: true
    };

    let types = API.getFirmwaresTypeVariants();

    return {
      isPageLoading: false,
      firmware: firmware,
      image: "",
      types: types,
      confirmData: {
        Id: 0,
        Version: 0
      }
    };
  },
  computed: {},
  filters: {},
  methods: {
    save() {
      let self = this;

      self.isPageLoading = true;
      API.private
        .addNewFirmware(self.firmware.FirmwareType, self.firmware)
        .then(response => {
          self.confirmData = response.data;

          self.isPageLoading = false;

          self.$forceUpdate();
          self.$refs.codeModal.show();
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    }
  },

  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 1001;
}
</style>
